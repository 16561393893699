<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商家信息</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片区域 -->
    <el-card>
      <div class="table"
           style="width: 50%; margin-top: 10px">
        <el-form label-width="100px">
          <el-form-item label="商家品牌">
            <el-input v-model="title"></el-input>
          </el-form-item>
          <el-form-item label="客服电话">
            <el-input v-model="field"></el-input>
          </el-form-item>
          <el-form-item label="商家logo"
                        prop="mainPic">
            <el-upload :action="uploadURL"
                       list-type="picture-card"
                       :on-remove="picRemove"
                       :headers="headerObj"
                       :file-list="fileList"
                       :on-success="handleSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btnAdd" @click="addGoods()">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

  </div>
</template>

<script>
export default {
  data () {
    return {
      title: '',
      field: '',
      links: '',
      childLinks: '',
      url: '',
      mainPic: '',
      backGroundColor: '',
      fileList: [],
      fileList2: [],
      fileList3: [],
      // 声明一个图片的地址
      uploadURL: '/api/file/uploadFile',
      headerObj: {
        Authorization: window.localStorage.getItem('token'),
      },
      //图片地址
      dialogImageUrl: null,
      // 控制图片的显示隐藏
      isEdit: false
    }
  },
  created () {
    const {query} = this.$route;
    if(query.id){
      this.groupInfo = localStorage.getItem('welfare-' + query.id);
      if(this.groupInfo){
        this.groupInfo = JSON.parse(this.groupInfo);

        this.isEdit = true;
        this.title = this.groupInfo.title;
        this.field = this.groupInfo.field;
        this.backGroundColor = this.groupInfo.backGroundColor;
        this.fileList = [{url: this.groupInfo.pic}]
        const list = JSON.parse(this.groupInfo.url);
        const arr1 = [], arr2=[];
        list.map(o => {
          arr1.push({url: o.pic});
          arr2.push(o.link);
        })
        this.fileList2 = arr1;
        this.links = arr2.join(',')
        
        const childList = JSON.parse(this.groupInfo.childUrl);
        const arr3 = [], arr4=[];
        childList.map(o => {
          arr3.push({url: o.pic});
          arr4.push(o.link);
        })
        this.fileList3 = arr3;
        this.childLinks = arr4.join(',')

      }
    }
    
  },
  methods: {
    // 图片删除
    picRemove (file, fileList) {
      this.fileList = []
    },
    // 图片上传成功之后的操作
    handleSuccess (res) {
      this.fileList = [{url: res.data }]
    },
    // 图片删除
    picRemove2 (file, fileList) {
      this.fileList2 = this.fileList2.filter(o => o.url != file.url);
    },
    // 图片上传成功之后的操作
    handleSuccess2 (res) {
      this.fileList2 = [].concat(this.fileList2, [{url: res.data }])
    },
    // 图片删除
    picRemove3 (file, fileList) {
      this.fileList3 = this.fileList3.filter(o => o.url != file.url);
    },
    // 图片上传成功之后的操作
    handleSuccess3 (res) {
      this.fileList3 = [].concat(this.fileList3, [{url: res.data }])
    },
    // 添加商品
    addGoods () {
      //  预校验
      if (!this.title) return this.$message.error('请输入标题')
      if (!this.field) return this.$message.error('请输入领劵字段')
      if (!this.fileList.length) return this.$message.error('请上传主图')
      if (!this.fileList2.length) return this.$message.error('请上传列表项图片')
      const linksArr = this.links.split(',')
      const urls = this.fileList2.map((o, index) => {
        return {
          pic: o.url,
          link: linksArr[index] || ''
        }
      })
      const childLinksArr = this.childLinks.split(',')
      const childUrls = this.fileList3.map((o, index) => {
        return {
          pic: o.url,
          link: childLinksArr[index] || ''
        }
      })
      const params = {
        title:this.title,field: this.field,
        pic: this.fileList[0].url,
        url: JSON.stringify(urls),
        backGroundColor: this.backGroundColor,
        childUrl: JSON.stringify(childUrls),
      }
      let promise = null;
      if(!this.isEdit){
        promise = this.$api.AddWelfare(params)
      }else{
        params.id = this.groupInfo.id;
        promise = this.$api.UpdateWelfare(params)
      }
      promise.then((res) => {
        if (res.code !== 200) {
          this.$message.error('保存失败')
        } else {
          this.$message.success('保存成功')
          this.$router.push('/welfare')
        }
      })
      
    },

  },
}
</script>

<style lang="scss" scoped></style>
